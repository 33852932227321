<template>
    <div class="home">
        <Top/>
        <Menu/>
        <div class="home_content">
            <div class="home_content00">
                <div class="home_content00_1">
                    <i class="el-icon-user"></i>
                    <div class="home_content00_11">{{ userName ? cutAccount(userName) : 'USERNAME' }}</div>
                </div>

                <div class="home_content00_2">
                    <TonConnectButton/>
                </div>
            </div>

            <div class="home_content01">
                <img class="home_content01_01" src="../../assets/images/jbi.png"/>
                <div class="home_content01_02"> {{ integralNum }} </div>
                <div class="home_content01_03"> GC </div>
            </div>

            <div class="home_content02">
                <div class="home_content02_01">Join telegram Premium, Earn more GC</div>
                <div class="home_content02_02"></div>
            </div>

            <div class="home_content03">
                <div class="home_content03_01">Time left: {{ timeLeftText }}</div>
                <div class="home_content03_02">Multipliers: {{ multipliers }}x >></div>
            </div>

            <div class="home_content04">
                <div v-show="miningStatus === 2002" class="home_content04_01" @click="onStartFarm">Start Farm</div>

                <div v-show="miningStatus === 2001" class="home_content04_01" @click="onStopFarm">
                    Farming:
                    <img src="../../assets/images/jbi.png"/>
                    {{ miningHarvest }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Top from "../../components/framing/Top";
    import Menu from "../../components/framing/Menu";
    import request from "../../tools/request";
    import toolUtils from "../../tools/toolUtils";

    import { TonConnectButton } from '@townsquarelabs/ui-vue';

    export default {
        name: "Index",
        components: {Top, Menu, TonConnectButton},
        data() {
            return {
                userId: null,
                userName: "USERNAME",
                integralNum: 0,
                timeLeft: 0,
                timeLeftText: "--",
                multipliers: 1,
                miningId: 0,
                miningStatus: 2002,
                miningHarvest: 0,
                miningStartTime: 0,
                miningEndTime: 0,
            }
        },
        watch: {},
        created() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }
            if (sessionStorage.getItem("userName")) {
                this.userName = sessionStorage.getItem("userName");
                sessionStorage.setItem("userName", this.userName);
            }
        },
        mounted() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }
            if (sessionStorage.getItem("userName")) {
                this.userName = sessionStorage.getItem("userName");
            }

            this.getUserInfoById();
            this.getVerifyUserLogin();
            this.getUserIntegral();
            this.getUserMining();
            this.miningCountdown();
        },
        methods: {
            async getVerifyUserLogin() {
                if (sessionStorage.getItem("userId")) {
                    this.userId = sessionStorage.getItem("userId");

                    await request.getVerifyUserLogin(sessionStorage.getItem("userId")).then((res) => {
                        if (!res.data) {
                            this.$router.push("/login");
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                } else {
                    this.$router.push("/login");
                }
            },
            async getUserIntegral() {
                if (this.userId) {
                    sessionStorage.setItem("userId", this.userId);

                    await request.getUserIntegral(this.userId, "GC").then((res) => {
                        if (res && res.code === 1 && res.data) {
                            if (res.data[0] && res.data[0].integral_num) {
                                this.integralNum = res.data[0].integral_num;
                            }
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                }
            },
            async getUserMining() {
                if (this.userId) {
                    await request.getUserMining(this.userId).then((res) => {
                        if (res.data) {
                            this.miningId = res.data.mining_id;
                            this.timeLeft = 0;
                            if (parseInt(res.data.mining_end_time) >= parseInt((new Date().getTime() / 1000).toString())) {
                                this.timeLeft = parseInt(res.data.mining_end_time) - parseInt((new Date().getTime() / 1000).toString());
                            }
                            this.multipliers = res.data.mining_factor;
                            this.miningStatus = res.data.mining_status;
                            this.miningStartTime = res.data.mining_start_time;
                            this.miningEndTime = res.data.mining_end_time;
                        } else {
                            this.miningId = 0;
                            this.timeLeft = 0;
                            this.miningStatus = 2002;
                            this.miningStartTime = 0;
                            this.miningEndTime = 0;
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                }
            },
            async miningCountdown() {
                if (this.timeLeft > 0) {
                    this.timeLeft -= 1;
                }
                this.timeLeftText = toolUtils.conversionTimestamp(this.timeLeft);
                this.timeLeftText = this.timeLeftText.toString().substring(5);

                if (this.miningStatus === 2001) {
                    let indexTime = parseInt((new Date().getTime() / 1000).toString());
                    if (indexTime >= this.miningEndTime) {
                        indexTime = this.miningEndTime;
                    }
                    this.miningHarvest = parseInt(((indexTime - this.miningStartTime) / 10) * parseFloat(this.multipliers));
                } else {
                    this.miningHarvest = 0;
                }

                setTimeout(() => {
                    this.miningCountdown();
                }, 1000);
            },
            async onStartFarm() {
                if (this.userId) {
                    await request.userStartMining(this.userId).then((res) => {
                        this.getUserIntegral();
                        this.getUserMining();

                        this.$message.success("Start mining");
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                }
            },
            async onStopFarm() {
                if (this.userId && this.miningId) {
                    const indexTime = parseInt((new Date().getTime() / 1000).toString());

                    if (indexTime >= this.miningEndTime) {
                        await request.userStopMining(this.userId, this.miningId).then((res) => {
                            this.getUserIntegral();
                            this.getUserMining();

                            this.$message.success("Mining completed");
                        }).catch((err) => {
                            this.$message.error(err.message);
                        })
                    } else {
                        this.$message.warning("End time not yet reached");
                    }
                }
            },
            async getUserInfoById() {
                if (this.userId) {
                    await request.getUserInfoById(this.userId).then((res) => {
                        if (res.data.user_name) {
                            this.userName = res.data.user_name;
                            sessionStorage.setItem("userName", res.data.user_name);
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    });
                }
            },
            cutAccount(val) {
                return val.length > 8 ? toolUtils.cutAccount(val, 3) : val;
            }
        }
    }
</script>

<style lang="less">
    .home {
        background-color: #131313;
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        .home_content {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
            .home_content00 {
                width: 100%;
                height: 60px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                .home_content00_1 {
                    width: 50%;
                    color: white;
                    font-size: 24px;
                    text-align: center;

                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    flex-direction: row;
                    justify-content: center;
                    .home_content00_11 {
                        font-size: 14px;
                        font-weight: 600;
                        margin-left: 5px;
                    }
                }
                .home_content00_2 {
                    width: 50%;
                    zoom: 0.85;
                    #ton-connect-button {
                        margin: 0 auto;
                    }
                }
            }
            .home_content01 {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
                .home_content01_01 {
                    width: 50px;
                    margin: 20px;
                }
                .home_content01_02 {
                    font-size: 25px;
                    font-weight: 600;
                    color: white;
                }
                .home_content01_03 {
                    font-size: 25px;
                    color: darkgray;
                    margin: 10px;
                }
            }
            .home_content02 {
                width: 400px;
                height: 100px;
                margin-top: 20px;
                background-image: url("../../assets/images/home_bj.jpg");
                background-size: cover; /* 背景图片覆盖整个div区域 */
                background-position: center; /* 背景图片居中 */
                .home_content02_01 {
                    width: 260px;
                    margin-left: 70px;
                    margin-top: 30px;
                    text-align: center;
                    color: white;
                    font-size: 18px;
                }
                .home_content02_02 {

                }
            }
            .home_content03 {
                width: 400px;
                height: 150px;
                color: white;
                font-size: 14px;
                .home_content03_01 {
                    height: 80px;
                    line-height: 80px;
                    text-align: center;
                }
                .home_content03_02 {
                    height: 50px;
                    text-align: center;
                }
            }
            .home_content04 {
                .home_content04_01 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;

                    width: 400px;
                    height: 60px;
                    background: #027d93;
                    border-radius: 10px;
                    border: darkgray solid 0.5px;
                    color: white;
                    font-size: 20px;
                    font-weight: 600;
                    text-align: center;
                    line-height: 60px;
                    cursor: pointer;
                    img {
                        width: 30px;
                        margin: 0 5px;
                    }
                }
            }
        }
    }
</style>
